import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function App() {
  return (
    <Container fluid>
      <Container className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <Container className="bg-light py-3 px-auto rounded text-center">
          <Row>
            <Col><h1>Benjamin King</h1></Col>
          </Row>
          <Row>
            <Col><p>Contact me: hello&lt;at&gt;benjaminking.info</p></Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default App;
